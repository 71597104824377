/* tslint:disable */
/* eslint-disable */
/**
 * BackendMain
 * The LifefitMainBack API description
 *
 * The version of the OpenAPI document: 1733132628936
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export * from './api';
export * from './configuration';
