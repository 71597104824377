var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"b08079d0b35a7879f1e3c8e74eba6ef1e0fcfc68"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://6207415aa7f01b037a0570979d880446@o4505233006985216.ingest.sentry.io/4505667984490496',
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  // tracesは従量課金制なので、一旦本番だけ100%(1.0)、それ以外は0%に設定
  tracesSampleRate: process.env.NEXT_PUBLIC_SENTRY_ENV === 'production' ? 0.3 : 0,
});
